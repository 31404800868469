
.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    .head-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 10px 30px 0 20px;

        .title {
            margin-top: 10px;
        }

        ::v-deep .el-button {
            background: #4D5AFF;
            border-radius: 2px;
            color: #ffffff;
            text-align: center;
        }

    }

    .divide {
        padding: 0 20px;
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .table-box {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;

        .showing {
            display: flex;
            align-items: center;
            /*width: 30px;*/
            height: 30px;
            margin-left: 50px;

            img {
                display: inline-block;
                width: 57px;
                height: 33px;
                margin-right: 10px;
            }

            .showing-name {
                max-width: 260px;
                line-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }
    }
}
